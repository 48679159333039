import React from "react";
import lazyLoad from "@/routers/utils/lazyLoad";
import { LayoutIndex } from "@/routers/constant";
import { RouteObject } from "@/routers/interface";
const sassPlatformRouter: Array<RouteObject> = [
	{
		element: <LayoutIndex />,
		meta: {
			title: "系统设置"
		},
		children: [
			{
				path: "/sassPlatform/configurationCenter",
				element: lazyLoad(React.lazy(() => import("@/views/sassPlatform/configurationCenter/index"))),
				meta: {
					requiresAuth: true,
					title: "配置中心",
					key: "configurationCenter"
				}
			},
			{
				path: "/sassPlatform/packageManagement",
				element: lazyLoad(React.lazy(() => import("@/views/sassPlatform/packageManagement/index"))),
				meta: {
					requiresAuth: true,
					title: "套餐管理",
					key: "packageManagement"
				}
			},
			{
				path: "/sassPlatform/menuManagement",
				element: lazyLoad(React.lazy(() => import("@/views/sassPlatform/menuManagement/index"))),
				meta: {
					requiresAuth: true,
					title: "菜单管理",
					key: "menuManagement"
				}
			},
			{
				path: "/sassPlatform/tenant",
				element: lazyLoad(React.lazy(() => import("@/views/sassPlatform/tenant/index"))),
				meta: {
					requiresAuth: true,
					title: "租户管理",
					key: "tenant"
				}
			},
			{
				path: "/sassPlatform/calculationAccount",
				element: lazyLoad(React.lazy(() => import("@/views/sassPlatform/calculationAccount/index"))),
				meta: {
					requiresAuth: true,
					title: "珠算账号",
					key: "calculationAccount"
				}
			}
		]
	}
];
export default sassPlatformRouter;
