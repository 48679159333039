import React from "react";
import lazyLoad from "@/routers/utils/lazyLoad";
import { LayoutIndex } from "@/routers/constant";
import { RouteObject } from "@/routers/interface";
const tools: Array<RouteObject> = [
	{
		element: <LayoutIndex />,
		meta: {
			title: "工具箱"
		},
		children: [
			{
				path: "/tools/cardGeneration",
				element: lazyLoad(React.lazy(() => import("@/views/tools/cardGeneration/index"))),
				meta: {
					requiresAuth: true,
					title: "珠译数",
					key: "cardGeneration"
				}
			},
			{
				path: "/tools/calculationAddition",
				element: lazyLoad(React.lazy(() => import("@/views/tools/calculationAddition/index"))),
				meta: {
					requiresAuth: true,
					title: "加减题库",
					key: "calculationAddition"
				}
			},
			{
				path: "/tools/calculationMultiplication",
				element: lazyLoad(React.lazy(() => import("@/views/tools/calculationMultiplication/index"))),
				meta: {
					requiresAuth: true,
					title: "乘除题库",
					key: "calculationMultiplication"
				}
			}
		]
	}
];
export default tools;
