import React from "react";
import lazyLoad from "@/routers/utils/lazyLoad";
import { LayoutIndex } from "@/routers/constant";
import { RouteObject } from "@/routers/interface";
const webRouter: Array<RouteObject> = [
	{
		element: <LayoutIndex />,
		meta: {
			title: "财务中心"
		},
		children: [
			{
				path: "/financialCenter/orderManagement",
				element: lazyLoad(React.lazy(() => import("@/views/financialCenter/orderManagement/index"))),
				meta: {
					requiresAuth: true,
					title: "订单管理",
					key: "orderManagement"
				}
			},
			{
				path: "/financialCenter/amountStatement",
				element: lazyLoad(React.lazy(() => import("@/views/financialCenter/amountStatement/index"))),
				meta: {
					requiresAuth: true,
					title: "收入支出",
					key: "amountStatement"
				}
			},
			{
				path: "/financialCenter/salaryComposition",
				element: lazyLoad(React.lazy(() => import("@/views/financialCenter/salaryComposition/index"))),
				meta: {
					requiresAuth: true,
					title: "薪资构成",
					key: "SalaryComposition"
				}
			},
			{
				path: "/financialCenter/payroll",
				element: lazyLoad(React.lazy(() => import("@/views/financialCenter/payroll/index"))),
				meta: {
					requiresAuth: true,
					title: "薪资管理",
					key: "payroll"
				}
			}
		]
	}
];
export default webRouter;
