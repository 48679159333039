import { ResPage, User } from "@/api/interface/index";
import { PORT } from "@/api/config/servicePort";

import http from "@/api";

/**
 * @name 租户模块
 */
// * 获取租户列表
export const $getTenantList = (params: any) => {
	return http.post<ResPage<User.ResUserList>>(PORT + `/tenant/pageList.page`, params);
};
// 新增租户
export const $tenantAdd = (params: any) => {
	return http.post(PORT + `/tenant/createTenant.page`, params);
};
// 编辑租户
export const $tenantEdit = (params: any) => {
	return http.post(PORT + `/tenant/updateTenant.page`, params);
};
// 删除租户
export const $tenantDelete = (params: { id: number }) => {
	return http.get(PORT + `/package/deletePackage.page?packageId=${params.id}`);
};
//  租户信息
export const $getPackageInfo = (params: { id: number }) => {
	return http.get(PORT + `/package/packageDetail.page?packageId=${params.id}`);
};
// 获取可分配菜单列表
export const $getMenuList = (params: any) => {
	return http.post(PORT + `/resource/search.page`, params);
};

// 业务设置查询
export const $loadSetting = () => {
	return http.get(PORT + `/setting/loadSetting.page`);
};

// 修改业务设置
export const $updateSetting = (params: any) => {
	return http.post(PORT + `/setting/updateSetting.page`, params);
};

// 添加珠算账号
export const $addCalculationUser = (params: any) => {
	return http.post(PORT + `/calculation/addCalculationUser.page`, params);
};

// 修改珠算账号
export const $updateCalculationUser = (params: any) => {
	return http.post(PORT + `/calculation/updateCalculationUser.page`, params);
};

// 删除珠算账号
export const $deleteCalculationUser = (params: { userId: number }) => {
	return http.get(PORT + `/calculation/deleteCalculationUser.page?userId=${params.userId}`);
};

// 珠算账号列表
export const $searchCalculationUser = (params: any) => {
	return http.post(PORT + `/calculation/searchCalculationUser.page`, params);
};
