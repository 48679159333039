import { message } from "antd";
import { setToken } from "@/redux/modules/global";
import { store } from "@/redux";
/**
 * @description: 校验网络请求状态码
 * @param {Number} status
 * @return void
 */
export const checkStatus = (status: number): void => {
	switch (status) {
		case 400:
			message.open({
				key: "api",
				type: "error",
				content: "请求失败！请您稍后重试"
			});
			break;
		case 401:
			message.open({
				key: "api",
				type: "error",
				content: "登录失效！请您重新登录"
			});
			store.dispatch(setToken(""));
			window.location.hash = "/login";
			break;
		case 403:
			message.open({
				key: "api",
				type: "error",
				content: "当前账号无权限访问！"
			});
			break;
		case 404:
			message.open({
				key: "api",
				type: "error",
				content: "你所访问的资源不存在！"
			});
			break;
		case 405:
			message.open({
				key: "api",
				type: "error",
				content: "请求方式错误！请您稍后重试"
			});
			break;
		case 408:
			message.open({
				key: "api",
				type: "error",
				content: "请求超时！请您稍后重试"
			});
			break;
		case 500:
			message.open({
				key: "api",
				type: "error",
				content: "服务异常！"
			});
			break;
		case 502:
			message.open({
				key: "api",
				type: "error",
				content: "网关错误！"
			});
			break;
		case 503:
			message.open({
				key: "api",
				type: "error",
				content: "服务不可用！"
			});
			break;
		case 504:
			message.open({
				key: "api",
				type: "error",
				content: "网关超时！"
			});
			break;
		default:
			message.open({
				key: "api",
				type: "error",
				content: "请求失败！"
			});
	}
};
