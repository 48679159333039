import screenfull from "screenfull";
import { App } from "antd";
import { useEffect, useState } from "react";
import { RootState, useSelector } from "@/redux";

const Fullscreen = () => {
	const [fullScreen, setFullScreen] = useState<boolean>(screenfull.isFullscreen);
	const { globalTheme } = useSelector((state: RootState) => state.global);
	const { message } = App.useApp();
	const transformColor = () => {
		return globalTheme === "light" ? "#000000" : "#ffffff";
	};
	useEffect(() => {
		screenfull.on("change", () => {
			if (screenfull.isFullscreen) setFullScreen(true);
			else setFullScreen(false);
			return () => screenfull.off("change", screenfullChange);
		});
	}, []);
	const screenfullChange = () => {
		console.log("screenfullChange");
	};
	const handleFullScreen = () => {
		if (!screenfull.isEnabled) message.warning("当前您的浏览器不支持全屏 ❌");
		screenfull.toggle();
	};
	return (
		<i
			style={{ color: transformColor() }}
			className={["icon-style iconfont", fullScreen ? "icon-suoxiao" : "icon-fangda"].join(" ")}
			onClick={handleFullScreen}
		></i>
	);
};
export default Fullscreen;
