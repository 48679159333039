import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const homeState: any = {
	noticeInfo: {
		banner: [],
		dynamics: [],
		notice: []
	},
	baseInfo: {
		studentList: []
	}
};

const home = createSlice({
	name: "home",
	initialState: homeState,
	reducers: {
		setNoticeInfo(state: any, { payload }: PayloadAction<any>) {
			state.noticeInfo = payload;
		},
		setBaseInfo(state: any, { payload }: PayloadAction<any>) {
			state.baseInfo = payload;
		}
	}
});

export default home.reducer;
export const { setNoticeInfo, setBaseInfo } = home.actions;
