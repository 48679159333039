import { ConfigProvider } from "antd";
import { HashRouter } from "react-router-dom";
import dayjs from "dayjs";
import AuthRouter from "@/routers/utils/authRouter";
import Router from "@/routers/index";
import zhCN from "antd/locale/zh_CN";
import "dayjs/locale/zh-cn";
import { App as AntdApp, theme } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "./redux";
dayjs.locale("zh-cn");
const App = () => {
	const { globalTheme, colorPrimary } = useSelector((state: RootState) => state.global);
	return (
		<HashRouter>
			<ConfigProvider
				locale={zhCN}
				theme={{
					algorithm: globalTheme === "light" ? theme.defaultAlgorithm : theme.darkAlgorithm,
					token: {
						colorPrimary
					}
				}}
			>
				<AntdApp>
					<AuthRouter>
						<Router />
					</AuthRouter>
				</AntdApp>
			</ConfigProvider>
		</HashRouter>
	);
};

export default App;
