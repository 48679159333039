import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import { updateCollapse } from "@/redux/modules/menu";
import { RootState, useDispatch, useSelector } from "@/redux";
import LayoutMenu from "./components/Menu";
import LayoutHeader from "./components/Header";
import LayoutTabs from "./components/Tabs";
import "./index.less";

const LayoutIndex = () => {
	const dispatch = useDispatch();
	const { isCollapse } = useSelector((state: RootState) => state.menu);
	const { globalTheme } = useSelector((state: RootState) => state.global);

	const { Sider, Content } = Layout;

	// 监听窗口大小变化
	const listeningWindow = () => {
		window.onresize = () => {
			return (() => {
				let screenWidth = document.body.clientWidth;
				if (!isCollapse && screenWidth < 1200) dispatch(updateCollapse(true));
				if (!isCollapse && screenWidth > 1200) dispatch(updateCollapse(false));
			})();
		};
	};

	useEffect(() => {
		listeningWindow();
		// getAuthButtonsList();
	}, []);

	return (
		// 这里不用 Layout 组件原因是切换页面时样式会先错乱然后在正常显示，造成页面闪屏效果
		<section className="container">
			<Layout>
				<LayoutHeader></LayoutHeader>
				<Layout>
					<Sider trigger={null} collapsed={isCollapse} width={220} theme="dark">
						<LayoutMenu></LayoutMenu>
					</Sider>
					<Layout style={{ background: globalTheme === "light" ? "#fdfdfd" : "#141414" }}>
						<LayoutTabs></LayoutTabs>
						<Content>
							<Outlet></Outlet>
						</Content>
					</Layout>
				</Layout>
			</Layout>
		</section>
	);
};

export default LayoutIndex;
