import React from "react";
import lazyLoad from "@/routers/utils/lazyLoad";
import { LayoutIndex } from "@/routers/constant";
import { RouteObject } from "@/routers/interface";
const platformRouter: Array<RouteObject> = [
	{
		element: <LayoutIndex />,
		meta: {
			title: "系统设置"
		},
		children: [
			{
				path: "/platform/roleManagement",
				element: lazyLoad(React.lazy(() => import("@/views/platform/roleManagement/index"))),
				meta: {
					requiresAuth: true,
					title: "角色管理",
					key: "roleManagement"
				}
			},
			{
				path: "/platform/userManagement",
				element: lazyLoad(React.lazy(() => import("@/views/platform/userManagement/index"))),
				meta: {
					requiresAuth: true,
					title: "用户管理",
					key: "userManagement"
				}
			},
			{
				path: "/platform/logManagement",
				element: lazyLoad(React.lazy(() => import("@/views/platform/logManagement/index"))),
				meta: {
					requiresAuth: true,
					title: "操作日志",
					key: "logManagement"
				}
			}
		]
	}
];
export default platformRouter;
