import { Layout, App } from "antd";
import { RootState, useSelector, useDispatch } from "@/redux";
import AvatarIcon from "./components/AvatarIcon";
import Fullscreen from "./components/Fullscreen";
import Logo from "./components/Logo";
import { useEffect, useRef } from "react";
import { setUserInfo } from "@/redux/modules/global";
import { $userInfoByToken, $loadTenantExprTime } from "@/api/modules/login";
import { SmileOutlined } from "@ant-design/icons";
import BusinessSettingDrawer from "@/layouts/components/Header/components/BusinessSettingDrawer";
import "./index.less";
const LayoutHeader = () => {
	const { Header } = Layout;
	const dispatch = useDispatch();
	const { globalTheme, userInfo } = useSelector((state: RootState) => state.global);
	const operateRef = useRef<any>(null);
	const transformColor = () => {
		return {
			bg: globalTheme === "light" ? "#ffffff" : "#141414",
			border: globalTheme === "light" ? "#f0f0f0" : "#141414"
		};
	};
	const { notification } = App.useApp();
	useEffect(() => {
		getUserInfo();
		loadTenantExprTime();
	}, []);
	const loadTenantExprTime = async () => {
		const res: any = await $loadTenantExprTime();
		if (res.success) {
			notification.open({
				message: "欢迎回来",
				description: res.data,
				icon: <SmileOutlined rev={undefined} />,
				placement: "bottomRight"
			});
		}
	};
	const getUserInfo = async () => {
		const { data } = await $userInfoByToken();
		dispatch(setUserInfo(data));
	};

	const operateConfig = () => {
		operateRef.current.showDrawer();
	};
	return (
		<Header style={{ background: transformColor().bg, borderColor: transformColor().border }}>
			<Logo></Logo>
			<div className="flex gap-[16px] items-center">
				{/*<ChangePrimaryColor />*/}
				{/* <IconFont
					className="text-[30px]"
					onClick={changeTheme}
					type={globalTheme === "light" ? "icon-ClearNight-qing-yewan" : "icon-Sunny-qing-baitian"}
				/> */}
				<i className="fas fas fa-cog text-[18px]" onClick={operateConfig}></i>
				<Fullscreen />
				<span className="username">{userInfo.userName || "人员"}</span>
				<AvatarIcon avatar={userInfo.avatar} />
				<BusinessSettingDrawer innerRef={operateRef}></BusinessSettingDrawer>
			</div>
		</Header>
	);
};

export default LayoutHeader;
