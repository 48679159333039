import { useRef } from "react";
import { Avatar, Dropdown, App } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { HOME_URL } from "@/config/config";
import { useDispatch } from "@/redux";
import { setToken, setUserInfo } from "@/redux/modules/global";
import PasswordModal from "./PasswordModal";
import type { MenuProps } from "antd";
import { $pcLoginOut } from "@/api/modules/login";
const AvatarIcon = (props: any) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { message, modal } = App.useApp();
	interface ModalProps {
		showModal: (params: { name: number }) => void;
	}
	const passRef = useRef<ModalProps>(null);

	// 退出登录
	const logout = () => {
		modal.confirm({
			title: "温馨提示 🧡",
			icon: <ExclamationCircleOutlined rev={undefined} />,
			content: "是否确认退出登录？",
			okText: "确认",
			cancelText: "取消",
			onOk: async () => {
				const data: any = await $pcLoginOut();
				if (data.success) {
					dispatch(setToken(""));
					dispatch(setUserInfo(""));
					message.success("退出登录成功！");
					navigate("/login");
				}
			}
		});
	};

	// Dropdown Menu
	const items: MenuProps["items"] = [
		{
			key: "1",
			label: <span className="dropdown-item">首页</span>,
			onClick: () => navigate(HOME_URL)
		},
		{
			key: "2",
			label: <span className="dropdown-item">修改密码</span>,
			onClick: () => passRef.current!.showModal({ name: 11 })
		},
		{
			type: "divider"
		},
		{
			key: "4",
			label: <span className="dropdown-item">退出登录</span>,
			onClick: logout
		}
	];
	return (
		<>
			<Dropdown menu={{ items }} placement="bottom" arrow trigger={["hover"]}>
				<Avatar className="cursor-pointer" size="large" src={props.avatar} />
			</Dropdown>
			<PasswordModal innerRef={passRef}></PasswordModal>
		</>
	);
};

export default AvatarIcon;
