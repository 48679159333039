import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const scheduleState: any = {
	currentCreateTimeInfo: {
		date: "",
		start: "",
		end: ""
	},
	reloadTime: new Date().getTime(),
	currentScheduleInfo: {},
	updateDetails: new Date().getTime(),
	searchParams: {
		classGroupId: "0",
		courseId: "0",
		teacherId: "0",
		type: ""
	},
	start: "",
	end: ""
};

const schedule = createSlice({
	name: "schedule",
	initialState: scheduleState,
	reducers: {
		setCreateTime(state: any, { payload }: PayloadAction<any>) {
			state.currentCreateTimeInfo = payload;
		},
		setReloadTime(state: any, { payload }: PayloadAction<any>) {
			state.reloadTime = payload;
		},
		setScheduleInfo(state: any, { payload }: PayloadAction<any>) {
			state.currentScheduleInfo = payload;
		},
		updateScheduleDetails(state: any, { payload }: PayloadAction<any>) {
			state.updateDetails = payload;
		},
		setSearchParams(state: any, { payload }: PayloadAction<any>) {
			state.searchParams = payload;
		},
		setStart(state: any, { payload }: PayloadAction<any>) {
			state.start = payload;
		},
		setEnd(state: any, { payload }: PayloadAction<any>) {
			state.end = payload;
		}
	}
});

export default schedule.reducer;
export const { setCreateTime, setReloadTime, setScheduleInfo, updateScheduleDetails, setSearchParams, setStart, setEnd } =
	schedule.actions;
