import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Menu, Spin } from "antd";
import { findAllBreadcrumb, getOpenKeys, handleRouter, searchRoute } from "@/utils/util";
import { setMenuList as reduxSetMenuList } from "@/redux/modules/menu";
import { setBreadcrumbList } from "@/redux/modules/breadcrumb";
import { setAuthRouter } from "@/redux/modules/auth";
import { $getUserMenu } from "@/api/modules/login";
import { RootState, useDispatch, useSelector } from "@/redux";
import type { MenuProps } from "antd";
import "./index.less";
const LayoutMenu = () => {
	const dispatch = useDispatch();
	const { isCollapse, menuList: reduxMenuList } = useSelector((state: RootState) => state.menu);
	const { siderTheme } = useSelector((state: RootState) => state.global);
	const { pathname } = useLocation();
	const [selectedKeys, setSelectedKeys] = useState<string[]>([pathname]);
	const [openKeys, setOpenKeys] = useState<string[]>([]);

	// 刷新页面菜单保持高亮
	useEffect(() => {
		setSelectedKeys([pathname]);
		isCollapse ? null : setOpenKeys(getOpenKeys(pathname));
	}, [pathname, isCollapse]);

	// 设置当前展开的 subMenu
	const onOpenChange = (openKeys: string[]) => {
		if (openKeys.length === 0 || openKeys.length === 1) return setOpenKeys(openKeys);
		const latestOpenKey = openKeys[openKeys.length - 1];
		if (latestOpenKey.includes(openKeys[0])) return setOpenKeys(openKeys);
		setOpenKeys([latestOpenKey]);
	};

	// 定义 menu 类型
	type MenuItem = Required<MenuProps>["items"][number];
	const getItem = (
		label: React.ReactNode,
		key?: React.Key | null,
		icon?: React.ReactNode,
		children?: MenuItem[],
		type?: "group"
	): MenuItem => {
		return {
			key,
			icon,
			children,
			label,
			type
		} as MenuItem;
	};

	// 动态渲染 Icon 图标
	const addIcon = (name: any) => {
		if (name) return <i className={`${name} text-[16px] ant-menu-item-icon`} />;
		return <></>;
	};

	// 处理后台返回菜单 key 值为 antd 菜单需要的 key 值
	const deepLoopFloat = (menuList: any, newArr: any = []) => {
		menuList.forEach((item: any) => {
			if (!item?.children?.length) return newArr.push(getItem(item.meta.title, item.path, addIcon(item.meta.icon)));
			newArr.push(getItem(item.meta.title, item.path, addIcon(item.meta.icon), deepLoopFloat(item.children)));
		});
		return newArr;
	};
	// 获取菜单列表并处理成 antd menu 需要的格式
	const [menuList, setMenuList] = useState<MenuItem[]>([]);
	const [loading, setLoading] = useState(false);
	const getMenuData = async () => {
		setLoading(true);
		try {
			const { data }: any = await $getUserMenu();
			if (!data) return;
			setMenuList(deepLoopFloat(data));
			// 存储处理过后的所有面包屑导航栏到 redux 中
			dispatch(setBreadcrumbList(findAllBreadcrumb(data)));
			// 把路由菜单处理成一维数组，存储到 redux 中，做菜单权限判断
			const dynamicRouter = handleRouter(data);
			dispatch(setAuthRouter(dynamicRouter));
			dispatch(reduxSetMenuList(data));
		} finally {
			setLoading(false);
		}
	};
	useEffect(() => {
		getMenuData();
	}, []);

	// 点击当前菜单跳转页面
	const navigate = useNavigate();
	const clickMenu: MenuProps["onClick"] = ({ key }: { key: string }) => {
		const route: any = searchRoute(key, reduxMenuList);
		if (route.component.startsWith("http")) {
			window.open(key, "_blank");
			return;
		}
		navigate(key);
	};

	return (
		<div className="menu">
			<Spin spinning={loading}>
				<Menu
					theme={siderTheme}
					mode="inline"
					openKeys={openKeys}
					selectedKeys={selectedKeys}
					items={menuList}
					onClick={clickMenu}
					onOpenChange={onOpenChange}
				></Menu>
			</Spin>
		</div>
	);
};

export default LayoutMenu;
