import React from "react";
import lazyLoad from "@/routers/utils/lazyLoad";
import { LayoutIndex } from "@/routers/constant";
import { RouteObject } from "@/routers/interface";
const webRouter: Array<RouteObject> = [
	{
		element: <LayoutIndex />,
		meta: {
			title: "教务管理"
		},
		children: [
			{
				path: "/web/courseManagement",
				element: lazyLoad(React.lazy(() => import("@/views/web/courseManagement/index"))),
				meta: {
					requiresAuth: true,
					title: "课程管理",
					key: "configurationCenter"
				}
			},
			{
				path: "/web/collegeManagement",
				element: lazyLoad(React.lazy(() => import("@/views/web/collegeManagement/index"))),
				meta: {
					requiresAuth: true,
					title: "学员管理",
					key: "collegeManagement"
				}
			},
			{
				path: "/web/registrationPayment",
				element: lazyLoad(React.lazy(() => import("@/views/web/registrationPayment/index"))),
				meta: {
					requiresAuth: true,
					title: "报名缴费",
					key: "registrationPayment"
				}
			},
			{
				path: "/web/schedule",
				element: lazyLoad(React.lazy(() => import("@/views/web/schedule/index"))),
				meta: {
					requiresAuth: true,
					title: "课表",
					key: "schedule"
				}
			},
			{
				path: "/web/classGroupManagement",
				element: lazyLoad(React.lazy(() => import("@/views/web/classGroupManagement/index"))),
				meta: {
					requiresAuth: true,
					title: "班组管理",
					key: "classGroupManagement"
				}
			},
			{
				path: "/web/classRecord",
				element: lazyLoad(React.lazy(() => import("@/views/web/classRecord/index"))),
				meta: {
					requiresAuth: true,
					title: "上课记录",
					key: "classRecord"
				}
			},
			{
				path: "/web/leaveApproval",
				element: lazyLoad(React.lazy(() => import("@/views/web/leaveApproval/index"))),
				meta: {
					requiresAuth: true,
					title: "请假审批",
					key: "classRecord"
				}
			}
		]
	}
];
export default webRouter;
