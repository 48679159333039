import LoginForm from "./components/LoginForm";
import loginLeft from "@/assets/images/login_left5.png";
import "./index.less";
import QrLoginForm from "@/views/login/components/QrLoginForm";
import { Tabs } from "antd";
import logo from "@/assets/images/logo.png";
import LayoutFooter from "@/layouts/components/Footer";
import { useRef } from "react";

const Login = () => {
	const innerRef = useRef<any>(null);
	const tabItems: any[] = [
		{
			label: `账号密码登录`,
			key: 1,
			children: <LoginForm />
		},
		{
			label: `扫码登录`,
			key: 2,
			children: <QrLoginForm innerRef={innerRef} />
		}
	];
	const tabChange = (activeKey: any) => {
		if (activeKey === 1) {
			innerRef.current.clearTimer();
		} else if (activeKey === 2) {
			innerRef.current.getCode();
		}
	};

	return (
		<div className="login-container">
			<div className="login-box">
				<div className="flex items-center justify-center">
					<div className="login-left">
						<img src={loginLeft} alt="login" />
					</div>
					<div className="login-form relative">
						<div className="login-logo">
							<img className="login-icon" src={logo} alt="logo" />
							<span className="logo-text">芒果助教</span>
						</div>
						<Tabs items={tabItems} onChange={tabChange} />
					</div>
				</div>
				<LayoutFooter></LayoutFooter>
			</div>
		</div>
	);
};

export default Login;
