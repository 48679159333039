import { PORT } from "@/api/config/servicePort";
import http from "@/api";

/**
 * @name 登录模块
 */
// * 用户登录接口
// export const loginApi = (params: Login.ReqLoginForm) => {
// 	return http.post<Login.ResLogin>(PORT + `/login`, params);
// 	return http.post<Login.ResLogin>(PORT + `/login`, {}, { params }); // post 请求携带 query 参数  ==>  ?username=admin&password=123456
// 	return http.post<Login.ResLogin>(PORT + `/login`, qs.stringify(params)); // post 请求携带 表单 参数  ==>  application/x-www-form-urlencoded
// 	return http.post<Login.ResLogin>(PORT + `/login`, params, { headers: { noLoading: true } }); // 控制当前请求不显示 loading
// };

// // * 获取按钮权限
// export const getAuthorButtons = () => {
// 	return http.get<Login.ResAuthButtons>(PORT + `/auth/buttons`);
// };

// // * 获取菜单列表
// export const getMenuList = () => {
// 	return http.get<Menu.MenuOptions[]>(PORT + `/menu/list`);
// };

// 获取验证码
export const $getCode = () => {
	return http.get(PORT + `/auth/imageCode.page`, {}, { headers: { noLoading: true } });
};

// 扫码登录状态
export const $pcAuthStatus = (params: { scene: string }) => {
	return http.get(PORT + `/auth/pcAuthStatus.page?scene=${params.scene}`, {}, { headers: { noLoading: true } });
};

// 二维码登录接口
export const $qrLoginImage = () => {
	return http.get(PORT + `/auth/qrLoginImage.page`, {}, { headers: { noLoading: true } });
};

// 登录
export const $login = (params: any) => {
	return http.post(PORT + `/auth/pcLogin.page`, params);
};

// 获取用户所对应租户
export const $getUserTenant = (params: { mobile: string }) => {
	return http.get(PORT + `/tenant/loadUserTenant.page?mobile=${params.mobile}`);
};
// token获取用户信息
export const $userInfoByToken = () => {
	return http.get(PORT + `/auth/userInfoByToken.page`);
};
// 获取用户菜单
export const $getUserMenu = () => {
	return http.get(PORT + `/resource/loadUserRoute.page`);
};
// 退出登录
export const $pcLoginOut = () => {
	return http.get(PORT + `/auth/pcLoginOut.page`);
};
// 修改密码
export const $changePassword = (params: any) => {
	return http.post(PORT + `/auth/changePassword.page`, params);
};
// 到期日期
export const $loadTenantExprTime = () => {
	return http.get(PORT + `/auth/loadTenantExprTime.page`);
};
