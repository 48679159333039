import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const globalState: any = {
	token: "",
	userInfo: "",
	globalTheme: "light",
	colorPrimary: "rgb(254,102,23)"
};

const globalSlice = createSlice({
	name: "global",
	initialState: globalState,
	reducers: {
		setToken(state: any, { payload }: PayloadAction<string>) {
			state.token = payload;
		},
		setTheme(state: any, { payload }: PayloadAction<any>) {
			state.globalTheme = payload;
		},
		setUserInfo(state: any, { payload }: PayloadAction<any>) {
			state.userInfo = payload;
		},
		setColorPrimary(state: any, { payload }: PayloadAction<any>) {
			state.colorPrimary = payload;
		}
	}
});

export const { setToken, setTheme, setUserInfo, setColorPrimary } = globalSlice.actions;
export default globalSlice.reducer;
