import { RootState, useDispatch, useSelector } from "@/redux";
import { updateCollapse } from "@/redux/modules/menu";
const CollapseIcon = () => {
	const dispatch = useDispatch();
	const { isCollapse } = useSelector((state: RootState) => state.menu);
	return (
		<div
			className="collapsed"
			onClick={() => {
				dispatch(updateCollapse(!isCollapse));
			}}
		>
			{isCollapse ? (
				<i className="fas fa-indent" style={{ color: "#000000" }} />
			) : (
				<i className="fas fa-outdent" style={{ color: "#000000" }} />
			)}
		</div>
	);
};

export default CollapseIcon;
