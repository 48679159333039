import { Modal, message } from "antd";
import { useState, useImperativeHandle } from "react";
const SelectCampusModel = (props: any) => {
	const [current, setCurrent] = useState<any>();
	const [modalVisible, setModalVisible] = useState(false);
	useImperativeHandle(props.innerRef, () => ({
		showModal
	}));

	const showModal = () => {
		setModalVisible(true);
	};
	const handleOk = () => {
		if (current) {
			props.login(current);
		} else {
			message.info("请选择校区");
		}
	};
	const onCancel = () => {
		setCurrent(null);
		setModalVisible(false);
		props.getCode();
	};
	const selectCampus = (item: any) => {
		if (current !== item.id) setCurrent(item.id);
	};
	return (
		<Modal title="选择校区" width={800} maskClosable={false} open={modalVisible} onOk={handleOk} onCancel={onCancel}>
			{props.list.map((item: any) => (
				<div
					className={[current === item.id ? "card-actived" : "", "card"].join(" ")}
					title={item.tenantName}
					key={item.id}
					onClick={() => selectCampus(item)}
				>
					<div>{item.tenantName}</div>
					<div>负责人： {item.principal}</div>
				</div>
			))}
		</Modal>
	);
};

export default SelectCampusModel;
