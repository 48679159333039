import logo from "@/assets/images/logo.png";
import { RootState, useSelector } from "@/redux";
const Logo = () => {
	const { userInfo, globalTheme } = useSelector((state: RootState) => state.global);
	const transformColor = () => {
		return globalTheme === "light" ? "#000000" : "#ffffff";
	};
	return (
		<div className="flex h-[55px] justify-center items-center">
			<img src={logo} alt="logo" className="w-[50px]" />
			<h2 style={{ color: transformColor() }} className="ml-[10px] text-[24px] mb-0 font-bold">
				{userInfo?.userTenantInfo?.tenantName}
			</h2>
		</div>
	);
};

export default Logo;
