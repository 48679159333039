import { useEffect, useImperativeHandle, useState } from "react";
import { App, Image } from "antd";
import { $qrLoginImage, $pcAuthStatus } from "@/api/modules/login";
import { setToken } from "@/redux/modules/global";
import { setTabsList } from "@/redux/modules/tabs";
import { HOME_URL } from "@/config/config";
import { useDispatch } from "@/redux";
import { useNavigate } from "react-router-dom";

let timer: any = null;
const QrLoginForm = (props: any) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { message } = App.useApp();
	const [picPath, setPicPath] = useState<string>("");
	const [scene, setScene] = useState<string>("");
	const [isExpire, setIsExpire] = useState<boolean>(false);
	useImperativeHandle(props.innerRef, () => ({
		clearTimer,
		getCode
	}));
	const clearTimer = () => {
		if (timer) {
			clearInterval(timer);
			timer = null;
		}
	};
	const scanCode = async () => {
		try {
			const { code, data }: any = await $pcAuthStatus({ scene: scene });
			console.log(code);
			if (code === 0 || code === 2) {
				clearTimer();
				setIsExpire(true);
			} else if (code === 200) {
				dispatch(setToken(data.token));
				dispatch(setTabsList([]));
				message.success("登录成功！");
				navigate(HOME_URL);
				clearTimer();
			}
		} catch (e) {
			clearTimer();
		}
	};
	const getCode = async () => {
		setIsExpire(false);
		const { data }: any = await $qrLoginImage();
		setScene(data.captchaId);
		setPicPath(data.picPath);
	};
	useEffect(() => {
		getCode();
	}, []);

	useEffect(() => {
		if (scene) {
			timer = setInterval(() => {
				scanCode();
			}, 3000);
		}
	}, [scene]);
	return (
		<>
			<div className="flex flex-col relative">
				<div className="w-[370px] h-[310px] flex items-center justify-center">
					<Image width={300} height={300} src={picPath} preview={false} />
				</div>
				{isExpire ? (
					<div
						className="w-[370px] h-[310px] absolute left-[0] top-[0] flex justify-center items-center login-glass"
						onClick={getCode}
					>
						<i className="fas fa-sync-alt text-[#fff] text-[32px]"></i>
					</div>
				) : (
					<div></div>
				)}
			</div>
		</>
	);
};
export default QrLoginForm;
