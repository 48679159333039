import { FooterBtn } from "@/components/common/FooterBtn";
import { $loadSetting, $updateSetting } from "@/api/modules/tenant";
import { Drawer, Form, Button, App, Switch, Radio } from "antd";
import { useState, useImperativeHandle } from "react";
import { setColorPrimary } from "@/redux/modules/global";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux";

const BusinessSettingDrawer = (props: any) => {
	const dispatch = useDispatch();
	const { colorPrimary } = useSelector((state: RootState) => state.global);
	const { message } = App.useApp();
	const [visible, setVisible] = useState(false);
	const [id, setId] = useState(false);
	const [form] = Form.useForm();
	useImperativeHandle(props.innerRef, () => ({
		showDrawer
	}));
	// 获取业务设置
	const loadSetting = async () => {
		const { data }: any = await $loadSetting();
		setId(data.id);
		form.setFieldsValue(data);
	};
	// 更新业务设置
	const updateSetting = async () => {
		const params = form.getFieldsValue(true);
		const { success }: any = await $updateSetting({ ...params, id: id });
		if (success) {
			message.success("保存成功！");
		}
	};

	const showDrawer = () => {
		loadSetting();
		setTitle("业务设置");
		setVisible(true);
	};
	const [title, setTitle] = useState("");

	// 取消
	const onClose = () => {
		setVisible(false);
		form.resetFields();
	};
	const colors: any = [
		{
			color: "rgb(254,102,23)",
			bg: "rgba(254,102,23,.2)"
		},
		{
			color: "rgb(254, 181, 110)",
			bg: "rgba(254, 181, 110,.2)"
		},
		{
			color: "rgb(253, 116, 84)",
			bg: "rgba(253, 116, 84,.2)"
		},
		{
			color: "rgb(255,140,0)",
			bg: "rgba(255,140,0,.2)"
		},
		{
			color: "rgb(254,171 ,61)",
			bg: "rgba(254,171 ,61,.2)"
		}
	];
	const changeColor = (color: string) => {
		if (color === colorPrimary) return;
		dispatch(setColorPrimary(color));
	};

	return (
		<>
			<Drawer forceRender width={500} title={title} onClose={onClose} open={visible} className="bg-[red]">
				<div className="flex flex-col gap-[20px] pb-[50px]">
					<div className="text-[18px] font-bold">主题设置</div>
					<div className="grid grid-cols-5 gap-y-5 gap-x-4 mt-[10px]">
						{colors.map((item: any) => (
							<div
								key={item.color}
								onClick={() => changeColor(item.color)}
								style={{ background: item.color === colorPrimary ? item.bg : "" }}
								className="w-[60px] h-[60px] flex cursor-pointer justify-center items-center border-1 border-solid rounded-[8px] border-[#f0f0f0]"
							>
								<div
									className={[`rounded-[50%]`, item.color === colorPrimary ? `w-[24px] h-[24px]` : `w-[10px] h-[10px]`].join(" ")}
									style={{ background: item.color }}
								></div>
							</div>
						))}
					</div>
					<div className="text-[18px] font-bold mt-[10px]">业务设置</div>
					<div className="mt-[10px]">
						<Form form={form} name="business-form">
							<div
								className="flex flex-col rounded-[8px]"
								style={{
									background: `linear-gradient(180deg, #fff -50%, rgb(255 239 224) 100%)`
								}}
							>
								<div className="flex items-center gap-[10px] border-b border-[red] border-[1px] px-[10px] py-[10px]">
									<div
										className="w-[6px] h-[15px] bg-[red] rounded-[8px]"
										style={{
											background: `${colorPrimary}`
										}}
									></div>
									<div className="text-[16px] font-bold">课时设置</div>
								</div>
								<div className="p-[10px]">
									<Form.Item name="leaveReduce">
										<div className="flex justify-between items-center">
											<div className="flex flex-col">
												<div className="text-[14px]">学员缺课课时处理</div>
												<div className="text-[#888] text-[12px]">选择缺课处理方式</div>
											</div>
											<div>
												<Form.Item name="leaveReduce">
													<Radio.Group>
														<Radio value="NO_REDUCE">不扣</Radio>
														<Radio value="NORMAL_REDUCE">正常扣</Radio>
														<Radio value="DOUBLE_REDUCE">双倍扣</Radio>
													</Radio.Group>
												</Form.Item>
											</div>
										</div>
									</Form.Item>
								</div>
							</div>

							<div
								className="flex flex-col rounded-[8px] mt-[10px]"
								style={{
									background: `linear-gradient(180deg, #fff -50%, rgb(255 239 224) 100%)`
								}}
							>
								<div className="flex items-center gap-[10px] border-b border-[red] border-[1px] px-[10px] py-[10px]">
									<div
										className="w-[6px] h-[15px] bg-[red] rounded-[8px]"
										style={{
											background: `${colorPrimary}`
										}}
									></div>
									<div className="text-[16px] font-bold">点名设置</div>
								</div>
								<div className="p-[10px]">
									<Form.Item name="callForOverdueFees">
										<div className="flex justify-between items-center">
											<div className="flex flex-col">
												<div className="text-[14px]">欠费点名</div>
												<div className="text-[#888] text-[12px]">开启后 允许学员欠费</div>
											</div>
											<div>
												<Form.Item name="callForOverdueFees">
													<Switch />
												</Form.Item>
											</div>
										</div>
									</Form.Item>
								</div>
							</div>

							<div
								className="flex flex-col rounded-[8px] mt-[10px]"
								style={{
									background: `linear-gradient(180deg, #fff -50%, rgb(255 239 224) 100%)`
								}}
							>
								<div className="flex items-center gap-[10px] border-b border-[red] border-[1px] px-[10px] py-[10px]">
									<div
										className="w-[6px] h-[15px] bg-[red] rounded-[8px]"
										style={{
											background: `${colorPrimary}`
										}}
									></div>
									<div className="text-[16px] font-bold">家校设置</div>
								</div>
								<div className="p-[10px]">
									<Form.Item name="enableParent">
										<div className="flex justify-between items-center">
											<div className="flex flex-col">
												<div className="text-[14px]">开启家长端</div>
												<div className="text-[#888] text-[12px]">家长端可以看到学员信息</div>
											</div>
											<div>
												<Form.Item name="enableParent">
													<Switch />
												</Form.Item>
											</div>
										</div>
									</Form.Item>
									<Form.Item name="leaveApplication">
										<div className="flex justify-between items-center">
											<div className="flex flex-col">
												<div className="text-[14px]">家长端请假</div>
												<div className="text-[#888] text-[12px]">开启后 家长端可以发起请假申请</div>
											</div>
											<div>
												<Form.Item name="leaveApplication">
													<Switch />
												</Form.Item>
											</div>
										</div>
									</Form.Item>
									<Form.Item name="classComments">
										<div className="flex justify-between items-center">
											<div className="flex flex-col">
												<div className="text-[14px]">课后点评</div>
												<div className="text-[#888] text-[12px]">开启后 家长端可以看到学员课后点评</div>
											</div>
											<div>
												<Form.Item name="classComments">
													<Switch />
												</Form.Item>
											</div>
										</div>
									</Form.Item>
								</div>
							</div>
						</Form>
					</div>
				</div>
				<FooterBtn>
					<Button type="primary" onClick={updateSetting}>
						确定
					</Button>
					<Button onClick={onClose}>取消</Button>
				</FooterBtn>
			</Drawer>
		</>
	);
};

export default BusinessSettingDrawer;
