import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HOME_URL } from "@/config/config";
import { setTabsList } from "@/redux/modules/tabs";
import { RootState, useDispatch, useSelector } from "@/redux";
import { rootRouter } from "@/routers";
import { searchRoute } from "@/utils/util";
import MoreButton from "./components/MoreButton";
import CollapseIcon from "./components/CollapseIcon";
import "./index.less";

const LayoutTabs = () => {
	const dispatch = useDispatch();
	const { tabsList } = useSelector((state: RootState) => state.tabs);
	// const { TabPane } = Tabs;
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const [activeValue, setActiveValue] = useState<string>(pathname);

	useEffect(() => {
		addTabs();
	}, [pathname]);

	// click tabs
	const clickTabs = (key: string) => {
		navigate(key);
	};

	// add tabs
	const addTabs = () => {
		const route = searchRoute(pathname, rootRouter);
		let newTabsList = JSON.parse(JSON.stringify(tabsList));
		if (tabsList.every((item: any) => item.key !== route.path)) {
			newTabsList.push({
				label: route.meta!.title,
				key: route.path,
				closable: route.meta?.closable
			});
		}
		dispatch(setTabsList(newTabsList));
		setActiveValue(pathname);
	};

	// delete tabs
	const delTabs = (tabPath: string) => {
		if (tabPath === HOME_URL) return;
		if (pathname === tabPath) {
			tabsList.forEach((item: any, index: number) => {
				if (item.key !== pathname) return;
				const nextTab = tabsList[index + 1] || tabsList[index - 1];
				if (!nextTab) return;
				navigate(nextTab.key);
			});
		}
		dispatch(setTabsList(tabsList.filter((item: any) => item.key !== tabPath)));
	};

	return (
		<div className="tabs">
			<CollapseIcon />
			<Tabs
				items={tabsList}
				activeKey={activeValue}
				onTabClick={clickTabs}
				hideAdd
				type="editable-card"
				onEdit={path => {
					delTabs(path as string);
				}}
			></Tabs>
			<MoreButton delTabs={delTabs}></MoreButton>
		</div>
	);
};

export default LayoutTabs;
