import "./index.less";

const LayoutFooter = () => {
	return (
		<div className="footer flex gap-[10px]">
			<div>2024 © admin By 河南八鼎云网络科技有限公司</div>
			<div>|</div>
			<a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
				豫ICP备2024042745号-1
			</a>
		</div>
	);
};

export default LayoutFooter;
