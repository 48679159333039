import { RootState } from "@/redux";
import { useSelector } from "react-redux";

export const FooterBtn = (props: any) => {
	const { globalTheme } = useSelector((state: RootState) => state.global);
	return (
		<div
			style={{
				background: globalTheme === "light" ? "#ffffff" : "#1f1f1f",
				borderTop: globalTheme === "light" ? "1px solid rgba(5, 5, 5, 0.06)" : "1px solid rgba(253, 253, 253, 0.12)"
			}}
			className="absolute bottom-[0] left-[0] right-[0] h-[52px]"
		>
			<div className="flex items-center justify-end gap-[16px] px-[20px] h-full">{props.children}</div>
		</div>
	);
};
