import { useEffect, useState, useRef } from "react";
import { Button, Form, Input, Image, App } from "antd";
import { useNavigate } from "react-router-dom";
import { Login } from "@/api/interface";
import { HOME_URL } from "@/config/config";
import { setToken } from "@/redux/modules/global";
import { useDispatch } from "@/redux";
import { UserOutlined, LockOutlined, CloseCircleOutlined, SafetyOutlined } from "@ant-design/icons";
import { $getCode, $getUserTenant, $login } from "@/api/modules/login";
import md5 from "js-md5";
import SelectCampusModel from "./SelectCampusModel";
import { setTabsList } from "@/redux/modules/tabs";
const LoginForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const { message } = App.useApp();
	const [loading, setLoading] = useState<boolean>(false);
	const [picPath, setPicPath] = useState<string>("");
	const getCode = async () => {
		const { data }: any = await $getCode();
		setPicPath(data.picPath);
		form.setFieldValue("imageId", data.captchaId);
	};
	useEffect(() => {
		getCode();
	}, []);
	const onFinish = async (loginForm: Login.ReqLoginForm) => {
		try {
			setLoading(true);
			loginForm.password = md5(loginForm.password);
			const { data } = await $getUserTenant({
				mobile: loginForm.account
			});
			if ((data as any[]).length > 1) {
				setList(data);
				passRef.current.showModal();
			} else {
				await login((data as any[])[0].id as number);
			}
		} finally {
			setLoading(false);
		}
	};
	const [list, setList] = useState<any>([]);
	const passRef = useRef<any>(null);
	const login = async (tenantId: number) => {
		try {
			form.setFieldValue("tenantId", tenantId);
			const loginForm = form.getFieldsValue(true);
			const { data }: any = await $login(loginForm);
			dispatch(setToken(data.token));
			dispatch(setTabsList([]));
			message.success("登录成功！");
			navigate(HOME_URL);
		} catch (error) {
			getCode();
		}
	};
	return (
		<>
			<Form
				form={form}
				name="LoginForm"
				initialValues={{ account: "", password: "", imageCode: "", imageId: "", tenantId: null }}
				onFinish={onFinish}
				size="large"
				autoComplete="off"
			>
				<Form.Item name="account" rules={[{ required: true, message: "请输入用户名" }]}>
					<Input placeholder="请输入手机号" prefix={<UserOutlined rev={undefined} />} allowClear maxLength={11} />
				</Form.Item>
				<Form.Item name="password" rules={[{ required: true, message: "请输入密码" }]}>
					<Input.Password
						autoComplete="new-password"
						placeholder="请输入密码"
						allowClear
						prefix={<LockOutlined rev={undefined} />}
					/>
				</Form.Item>
				<Form.Item name="imageCode" rules={[{ required: true, message: "请输入验证码" }]}>
					<div className="flex gap-[10px]">
						<Input placeholder="请输入验证码" prefix={<SafetyOutlined rev={undefined} />} allowClear />
						<Image width={100} height={40} src={picPath} preview={false} className="cursor-pointer w-[100px]" onClick={getCode} />
					</div>
				</Form.Item>
				<Form.Item className="login-btn">
					<Button
						onClick={() => {
							form.resetFields();
						}}
						icon={<CloseCircleOutlined rev={undefined} />}
					>
						重置
					</Button>
					<Button type="primary" htmlType="submit" loading={loading} icon={<UserOutlined rev={undefined} />}>
						确定
					</Button>
				</Form.Item>
			</Form>
			{/* 选择弹框 */}
			<SelectCampusModel list={list} login={login} innerRef={passRef} getCode={getCode} />
		</>
	);
};

export default LoginForm;
