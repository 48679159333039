import { useState, useImperativeHandle, Ref } from "react";
import { Modal, message, Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "@/redux";
import { $pcLoginOut, $changePassword } from "@/api/modules/login";
import { setToken, setUserInfo } from "@/redux/modules/global";

interface Props {
	innerRef: Ref<{ showModal: (params: any) => void }>;
}

const PasswordModal = (props: Props) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useImperativeHandle(props.innerRef, () => ({
		showModal
	}));

	const showModal = () => {
		form?.resetFields();
		setIsModalVisible(true);
	};
	// 修改密码成功之后退出登录
	const loginOut = async () => {
		const data: any = await $pcLoginOut();
		if (data.success) {
			dispatch(setToken(""));
			dispatch(setUserInfo(""));
			navigate("/login");
		}
	};

	const handleOk = async () => {
		try {
			setLoading(true);
			await form.validateFields();
			const data: any = await $changePassword({ ...form.getFieldsValue(true) });
			if (data.success) {
				await loginOut();
			}
			handleCancel();
			message.success("修改密码成功 🎉🎉🎉");
		} finally {
			setLoading(false);
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const [loading, setLoading] = useState<boolean>(false);
	const [form] = Form.useForm();
	return (
		<Modal
			title="修改密码"
			open={isModalVisible}
			onCancel={handleCancel}
			destroyOnClose
			footer={[
				<Button key="back" onClick={handleCancel}>
					取消
				</Button>,
				<Button key="submit" htmlType="submit" type="primary" loading={loading} onClick={handleOk}>
					确定
				</Button>
			]}
		>
			<Form
				form={form}
				initialValues={{ oldPassword: "", newPassword: "", reNewPassword: "" }}
				name="control-hooks"
				onFinish={handleOk}
				labelCol={{ span: 5 }}
			>
				<Form.Item name="oldPassword" label="旧密码" rules={[{ required: true, message: "请输入旧密码" }]}>
					<Input.Password placeholder="请输入旧密码" />
				</Form.Item>
				<Form.Item name="newPassword" label="新密码" rules={[{ required: true, message: "请输入新密码" }]}>
					<Input.Password placeholder="请输入新密码" />
				</Form.Item>
				<Form.Item name="reNewPassword" label="确认新密码" rules={[{ required: true, message: "请输入确认新密码" }]}>
					<Input.Password placeholder="请输入确认新密码" />
				</Form.Item>
			</Form>
		</Modal>
	);
};
export default PasswordModal;
